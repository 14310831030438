export const INVERTED_BLUE_QUOTES = (
  <svg
    width="55"
    height="53"
    viewBox="0 0 55 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.3789 21.0137C23.3918 17.8031 22.028 15.0359 20.3272 12.7882C18.5763 10.4784 15.3034 7.28198 12.8916 6.1341C10.4649 4.97944 8.8648 5.95654 5.84815 6.19462C4.77338 6.27944 3.96591 7.26942 4.04559 8.40623L4.43327 13.9339L1.38037 15.0919L5.30315 18.1956C5.68199 18.5421 6.18591 18.7355 6.72466 18.693C10.5071 18.3946 12.8549 22.3924 13.7156 30.5806L7.59226 31.0638C6.51748 31.1487 5.71088 32.1395 5.79056 33.2758L6.83236 48.1317L3.64207 49.1556L7.76608 52.4391L7.77308 52.4366C8.14294 52.7451 8.61525 52.9191 9.12208 52.8791L25.6839 51.5721C26.7587 51.4874 27.566 50.4973 27.4863 49.3609L26.2452 31.6618C25.9693 27.7276 25.3412 24.1452 24.3789 21.0137Z"
      fill="#749BF3"
    />
    <path
      d="M53.2984 29.5267C53.0223 25.5907 52.3943 22.0081 51.4318 18.8777C50.4447 15.6678 49.0811 12.9008 47.3795 10.6532C45.629 8.34344 42.2479 5.06413 39.8366 3.91617C37.409 2.76145 35.9185 3.82149 32.9007 4.05964C31.8262 4.1444 31.0192 5.13431 31.099 6.27118L31.4831 11.7489L28.3948 12.9204L32.3265 16.0311L32.3286 16.0303C32.7104 16.3942 33.2244 16.6017 33.7778 16.558C37.614 16.2553 39.9923 20.2507 40.8573 28.4386L34.6454 28.9289C33.5702 29.0138 32.7633 30.0046 32.843 31.1409L33.8885 46.0503L30.74 47.0608L34.8641 50.3443L34.8765 50.3398C35.239 50.6227 35.6904 50.7823 36.1751 50.744L52.7375 49.437C53.8131 49.3521 54.6192 48.3614 54.5396 47.2259L53.2984 29.5267Z"
      fill="#749BF3"
    />
    <path
      d="M27.0589 3.06264L27.6467 11.4452C27.7264 12.5819 28.6626 13.4343 29.7377 13.3495C33.5738 13.0468 35.9522 17.0422 36.8172 25.2301L30.6053 25.7204C29.5301 25.8052 28.7232 26.7961 28.8029 27.9324L30.044 45.6316C30.1237 46.7679 31.0599 47.6204 32.135 47.5355L48.6974 46.2285C49.773 46.1436 50.579 45.1529 50.4995 44.0174L49.2583 26.3182C48.9822 22.3822 48.3541 18.7996 47.3917 15.6692C46.4046 12.4593 45.0409 9.69234 43.3394 7.44467C41.5889 5.13489 39.4788 3.3805 37.0675 2.2326C34.6399 1.07787 31.8784 0.61294 28.8606 0.851095C27.7861 0.93589 26.9791 1.9258 27.0589 3.06264ZM2.68446 15.4845C1.60983 15.5693 0.672919 14.7162 0.593256 13.5801L0.00543011 5.19771C-0.0742921 4.06086 0.733228 3.0709 1.80799 2.98609C4.82461 2.74804 7.58644 3.21307 10.0132 4.36772C12.425 5.5156 14.5361 7.2699 16.287 9.57967C17.9878 11.8273 19.3516 14.5945 20.3388 17.8051C21.301 20.9366 21.9291 24.519 22.205 28.4532L23.4462 46.1524C23.5258 47.2887 22.7186 48.2788 21.6437 48.3636L5.08192 49.6706C4.00729 49.7554 3.07119 48.9022 2.99156 47.7666L1.75038 30.0673C1.6707 28.931 2.47734 27.9401 3.5521 27.8553L9.67543 27.3721C8.8148 19.1839 6.4669 15.1861 2.68446 15.4845Z"
      fill="url(#paint0_linear_10661_12957)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_10661_12957"
        x1="4.34118"
        y1="5.11155"
        x2="42.2691"
        y2="47.4197"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D7E5FF" />
        <stop offset="1" stopColor="#D1DBFF" />
      </linearGradient>
    </defs>
  </svg>
);
