/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext, useState } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { TCityInfo } from 'components/AirportTransfers/interface';
import { getLangObject } from 'utils/helper';
import { addUrlParams } from 'utils/urlUtils';
import { SIDEBAR_TYPES } from 'const/index';

const AsideModal = dynamic(() => import('UI/AsideModal'), { ssr: false });

export const MBContext = createContext<{
  [x: string]: any;
  primaryCity: TCityInfo | null;
}>({
  uid: null,
  lang: 'en',
  language_full: null,
  nakedDomain: null,
  buttons: null,
  design: null,
  sidebarModal: {
    addToAside: null, //(modalProps: {children, title, width?}) => {}
    stack: [],
    closeAside: null,
    resetAside: null,
  },
  mbTheme: null,
  isPreview: false,
  currencySymbolMap: {},
  noTrack: false,
  biLink: '',
  isGlobalMb: false,
  host: '',
  isDev: false,
  bookSubdomain: 'book',
  primaryCountry: null,
  primaryCity: null,
  redirectToHeadoutBookingFlow: false,
  categoryHeaderMenu: {},
  userCountry: null,
});

export const MBContextProvider = (props: any) => {
  const {
    uid,
    lang,
    microsite,
    host,
    design,
    mbTheme,
    isPreview,
    currencySymbolMap,
    noTrack,
    biLink,
    isGlobalMb,
    isDev,
    bookSubdomain,
    primaryCountry,
    primaryCity,
    redirectToHeadoutBookingFlow,
    categoryHeaderMenu,
    userCountry,
  } = props;
  const [sidebarModalStack, setSidebarModalStack] = useState([]);
  const router = useRouter();
  // edge case
  //2. handle URLs for desktop
  // a. combo should redirect to booking flow
  // b. pid should scroll to that card

  const addToAside = ({
    children,
    title = '',
    width = '',
    sidePadding = 0,
    type = SIDEBAR_TYPES.DEFAULT,
    onCloseCallback,
    history,
    isProductCardTracking = false,
    tgid = '',
    hideCloseButton = false,
    noBackgroundOverlay = false,
  }: any) => {
    const modalState = {
      children,
      title,
      width,
      sidePadding,
      type,
      onCloseCallback,
      history,
      isProductCardTracking,
      tgid,
      hideCloseButton,
      noBackgroundOverlay,
    };

    // @ts-expect-error TS(2322): Type '{ children: any; title: any; width: any; sid... Remove this comment to see the full error message
    setSidebarModalStack([...sidebarModalStack, modalState]);
    if (history) {
      const {
        pid: routerPid,
        popup: routerPopup,
        ...otherParams
      } = router.query;
      //URL as a single source of truth
      const urlParams = new URLSearchParams(window.location.search);
      const pid = urlParams.get('pid');
      const popup = urlParams.get('popup');

      //for multilevel replace params
      if (pid && popup)
        addUrlParams({
          urlParams: { ...otherParams, ...history.params },
          historyState: { ...window.history.state, ...history.params },
          replace: true,
        });
      else
        addUrlParams({
          urlParams: { ...otherParams, ...history.params },
          historyState: { ...window.history.state, ...history.params },
          replace: false,
        });
    }
  };

  const closeAside = () => {
    setSidebarModalStack([...sidebarModalStack.slice(0, -1)]);
  };
  const resetAside = () => setSidebarModalStack([]);

  const getActiveAside = () =>
    sidebarModalStack && sidebarModalStack[sidebarModalStack.length - 1];

  const buttons = {
    see_more_text: microsite?.see_more_text,
  };
  const nakedDomain = !host?.includes('localhost')
    ? host?.split('.').slice(1).join('.')
    : 'headout.com';

  return (
    <MBContext.Provider
      value={{
        uid,
        lang: getLangObject(lang).code,
        language_full: lang,
        nakedDomain,
        buttons: buttons,
        design,
        sidebarModal: {
          stack: sidebarModalStack,
          addToAside,
          closeAside,
          resetAside,
        },
        mbTheme,
        isPreview,
        currencySymbolMap,
        noTrack,
        biLink,
        isGlobalMb,
        host,
        isDev,
        bookSubdomain,
        primaryCountry,
        primaryCity,
        redirectToHeadoutBookingFlow,
        categoryHeaderMenu,
        userCountry,
      }}
    >
      {props.children}
      {sidebarModalStack.length ? (
        <AsideModal
          resetAside={resetAside}
          active={sidebarModalStack.length}
          width={(getActiveAside() as any)?.width}
          sidePadding={(getActiveAside() as any)?.sidePadding}
          stack={sidebarModalStack}
          title={(getActiveAside() as any)?.title}
          closeModal={closeAside}
          type={(getActiveAside() as any)?.type}
          onCloseCallback={(getActiveAside() as any)?.onCloseCallback}
          isQueryRestore={(getActiveAside() as any)?.history?.isQueryRestore}
          isGlobalMb={isGlobalMb}
          isProductCardTracking={
            (getActiveAside() as any)?.isProductCardTracking
          }
          tgid={(getActiveAside() as any)?.tgid}
          hideCloseButton={(getActiveAside() as any)?.hideCloseButton}
          noBackgroundOverlay={(getActiveAside() as any)?.noBackgroundOverlay}
        >
          {(getActiveAside() as any)?.children}
        </AsideModal>
      ) : null}
    </MBContext.Provider>
  );
};
