export const INVERTED_BLUE_QUOTES_DWEB = (
  <svg
    width="119"
    height="115"
    viewBox="0 0 119 115"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.48">
      <path
        d="M74.0984 60.2835C73.0284 52.0854 70.8462 44.7758 67.6171 38.5565C64.2911 32.164 57.6011 22.9136 52.1616 19.0039C46.6884 15.0709 42.346 16.6626 34.8362 15.8019C32.1606 15.4952 29.7596 17.4946 29.4757 20.2691L28.0956 33.7601L20.1086 35.0967L28.434 44.4329C29.2184 45.4469 30.3744 46.1522 31.7156 46.3059C41.1318 47.3853 45.2118 58.1252 43.8709 78.2458L28.627 76.4986C25.9514 76.1919 23.5522 78.1939 23.2685 80.967L19.5592 117.225L11.2913 118.173L20.0352 128.038L20.0534 128.035C20.8317 128.954 21.9184 129.597 23.1801 129.742L64.4102 134.467C67.0859 134.774 69.4865 132.774 69.7702 130.001L74.1894 86.8037C75.1717 77.2018 75.1405 68.2794 74.0984 60.2835Z"
        fill="#749BF3"
      />
      <path
        d="M141.101 94.1828C142.084 84.5764 142.053 75.6536 141.01 67.6603C139.939 59.4639 137.758 52.1548 134.527 45.9353C131.202 39.543 124.281 30.0417 118.843 26.1321C113.367 22.1985 109.259 24.0419 101.746 23.1809C99.0714 22.8742 96.6716 24.8736 96.3879 27.6484L95.0201 41.0175L86.9405 42.3698L95.2848 51.7267L95.2903 51.7259C96.0747 52.7834 97.2496 53.5271 98.6273 53.6851C108.177 54.7797 112.333 65.5282 111.003 85.6502L95.5387 83.878C92.8618 83.5711 90.462 85.5728 90.1783 88.3462L86.4556 124.735L78.2961 125.671L87.0399 135.535L87.0724 135.53C87.8434 136.384 88.8847 136.982 90.0915 137.121L131.323 141.846C134.001 142.153 136.398 140.151 136.682 137.38L141.101 94.1828Z"
        fill="#749BF3"
      />
      <path
        d="M88.2868 18.0198L86.106 38.5271C85.8101 41.3081 87.7262 43.7994 90.3854 44.0928C99.8733 45.139 103.952 55.8855 102.531 76.0516L87.1669 74.3575C84.5075 74.0641 82.112 76.0821 81.8163 78.8621L77.2114 122.162C76.9157 124.942 78.8317 127.434 81.491 127.727L122.455 132.244C125.116 132.538 127.509 130.519 127.805 127.741L132.409 84.4415C133.433 74.8121 133.446 65.8731 132.448 57.8705C131.425 49.6648 129.292 42.3537 126.11 36.1397C122.836 29.7531 118.446 24.5406 113.059 20.6525C107.635 16.7405 101.1 14.3389 93.6355 13.5158C90.9778 13.2228 88.5825 15.2385 88.2868 18.0198ZM23.4734 36.7141C20.8155 36.421 18.898 33.9276 19.1936 31.1484L21.3744 10.6413C21.6702 7.86003 24.0669 5.84439 26.7252 6.13753C34.1863 6.96033 40.7227 9.36231 46.1443 13.2738C51.5324 17.162 55.9257 22.3748 59.2005 28.7615C62.3801 34.9751 64.5135 42.2868 65.537 50.4944C66.5338 58.4994 66.521 67.4382 65.4975 77.0629L60.8926 120.363C60.5969 123.143 58.2007 125.159 55.5423 124.866L14.5794 120.348C11.9215 120.055 10.006 117.562 10.3014 114.784L14.9063 71.4836C15.202 68.7039 17.5969 66.6855 20.2552 66.9786L35.4002 68.6488C36.8319 48.4842 32.8286 37.746 23.4734 36.7141Z"
        fill="url(#paint0_linear_10958_26465)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_10958_26465"
        x1="31.9878"
        y1="12.4418"
        x2="104.623"
        y2="133.127"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D7E5FF" />
        <stop offset="1" stopColor="#D1DBFF" />
      </linearGradient>
    </defs>
  </svg>
);
