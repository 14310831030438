export const UNDERLINE = (
  <svg
    width="61"
    height="2"
    viewBox="0 0 61 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.0532 1.86233C24.1783 1.80969 53.3718 0.978141 55.462 0.914987C61.3146 0.757095 62.1159 0.630786 59.7818 0.178167C56.542 -0.442866 27.6271 0.746568 9.12863 0.90446C0.523865 0.978142 -0.381893 1.03077 0.105827 1.39918C0.767723 1.96758 9.79054 2.15705 22.0532 1.86233Z"
      fill="#0D2A6F"
    />
  </svg>
);
