export const QnaPlus = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.99999 2.91663L6.99999 11.0833M2.91666 6.99996L11.0833 6.99996"
      stroke="#FF007A"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
