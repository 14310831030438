// App links
export const DROPS_MOBILE_BANNER_LINK =
  'https://headout.app.link/drops-mb-banner';
export const DROPS_MOBILE_EXIT_INTENT_LINK =
  'https://headout.app.link/drops-mb-intent';

// Image and asset URLs
export const DROPS_LOGO_URL =
  'https://cdn-imgix.headout.com/assets/images/drops/drops-logo.png';
export const DROPS_SPARK_ICON_URL =
  'https://cdn-imgix.headout.com/assets/images/drops/spark-icon.svg';
export const DROPS_SUPPLY_ICON_URL =
  'https://cdn-imgix.headout.com/assets/images/drops/supply-icon.svg';
export const DROPS_BOLT_ICON_URL =
  'https://cdn-imgix.headout.com/assets/images/drops/bolt-icon.svg';
export const DROPS_HIGHLIGHTED_TEXT_LINE_URL =
  'https://cdn-imgix.headout.com/assets/images/drops/highlighted-text-line.svg';
export const DROPS_BANNER_QR_CODE_URL =
  'https://cdn-imgix.headout.com/assets/images/drops/qr-mb-banner.png';
export const DROPS_EXIT_INTENT_QR_CODE_URL =
  'https://cdn-imgix.headout.com/assets/images/drops/qr-mb-intent.png';

export const DROPS_RIVE_URI =
  'https://cdn-imgix.headout.com/assets/rive/dropsBanner.riv';
export const dropsExitIntentAvailabilityClickedKey = `drops_shown_intent`;

// List of URLs eligible for Drops banner
export const DROPS_ELIGIBLE_URLS = [
  'dubai-tickets.co.burj-khalifa',
  'miraclegardentickets.com',
  'desert-safari-dxb.com',
  'dubai-tickets.co.dubai-global-village-tickets',
  'dubai-tickets.co.dubai-aquarium',
  'imgworldstickets.com',
  'dubai-tickets.co.dubai-frame',
  'dubai-tickets.co.museum-of-the-future',
  'dubai-tickets.co.aquaventure-waterpark',
  'dubai-tickets.co.dubai-dolphinarium',
  'palmtowertickets.com',
  'dubai-tickets.co.aya-universe',
];
