export const SparkGreenSVG = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_18058_3029)">
      <path
        d="M2.46298 7.22581L8.57542 2.01692C8.98041 1.67179 9.59143 2.04174 9.47335 2.56056L8.55037 6.61595C8.48259 6.91376 8.66906 7.21013 8.96687 7.27791L13.6905 8.35298C14.1268 8.45229 14.2707 9.00172 13.939 9.30212L6.74933 15.8135C6.34864 16.1763 5.7189 15.8079 5.83887 15.2808L7.09673 9.75404C7.16451 9.45623 6.97803 9.15986 6.68022 9.09208L2.69895 8.18596C2.25515 8.08496 2.11656 7.52102 2.46298 7.22581Z"
        fill="url(#paint0_linear_18058_3029)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_18058_3029"
        x1="9.84291"
        y1="0.936783"
        x2="6.15278"
        y2="17.1504"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#49EE9C" />
        <stop offset="1" stopColor="#078842" />
      </linearGradient>
      <clipPath id="clip0_18058_3029">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.550049)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const SupplySVG = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.1816 3.64096C10.4828 3.64096 10.727 3.39675 10.727 3.0955C10.727 2.79426 10.4828 2.55005 10.1816 2.55005H5.81792C5.51667 2.55005 5.27246 2.79426 5.27246 3.0955C5.27246 3.39675 5.51667 3.64096 5.81792 3.64096H8C4.68629 3.64096 2 6.32725 2 9.64096C2 12.9547 4.68629 15.641 8 15.641C11.3137 15.641 14 12.9547 14 9.64096C14 6.32725 11.3137 3.64096 8 3.64096H10.1816Z"
      fill="url(#paint0_linear_18058_3032)"
    />
    <path
      d="M9.69917 6.45298C9.74458 6.38688 9.846 6.44565 9.81123 6.51792L9.51872 7.12576C8.89488 8.42213 9.32817 9.9801 10.5318 10.7682C10.5839 10.8024 10.5408 10.8823 10.4834 10.8581C9.15902 10.2992 7.62338 10.8012 6.88709 12.0357L6.487 12.7066C6.44421 12.7783 6.33489 12.7236 6.36666 12.6463L6.71342 11.8028C7.24083 10.5199 6.78622 9.04309 5.6286 8.27887C5.57618 8.24426 5.62004 8.16412 5.67805 8.18823C6.95805 8.72038 8.44028 8.28539 9.22523 7.14284L9.69917 6.45298Z"
      fill="white"
    />
    <path
      d="M9.69917 6.45298C9.74458 6.38688 9.846 6.44565 9.81123 6.51792L9.51872 7.12576C8.89488 8.42213 9.32817 9.9801 10.5318 10.7682C10.5839 10.8024 10.5408 10.8823 10.4834 10.8581C9.15902 10.2992 7.62338 10.8012 6.88709 12.0357L6.487 12.7066C6.44421 12.7783 6.33489 12.7236 6.36666 12.6463L6.71342 11.8028C7.24083 10.5199 6.78622 9.04309 5.6286 8.27887C5.57618 8.24426 5.62004 8.16412 5.67805 8.18823C6.95805 8.72038 8.44028 8.28539 9.22523 7.14284L9.69917 6.45298Z"
      stroke="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_18058_3032"
        x1="8"
        y1="2.55005"
        x2="8"
        y2="15.641"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#49EE9C" />
        <stop offset="1" stopColor="#078842" />
      </linearGradient>
    </defs>
  </svg>
);

export const ZeroQuestionsSVG = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_18058_4146)">
      <path
        d="M7.94077 15.607L13.0667 15.5885C13.6636 15.5863 14.147 15.1032 14.1497 14.5063L14.1618 11.7481C14.1643 11.1843 13.8312 10.6849 13.4952 10.2322C13.3138 9.98783 13.1826 9.71001 13.1092 9.41474C13.0358 9.11946 13.0215 8.81257 13.0673 8.51176C13.113 8.21094 13.2179 7.92217 13.3758 7.66208C13.7381 7.07046 14.1506 6.45831 14.1435 5.7646L14.1214 3.59976C14.115 2.96922 13.6002 2.46245 12.9696 2.46599L7.90236 2.49442L3.70521 2.49432C3.11838 2.49431 2.64316 2.97099 2.64498 3.55782L2.65202 5.82813C2.65415 6.51556 3.05992 7.12555 3.42692 7.70684C3.58771 7.96359 3.69631 8.24951 3.74651 8.54827C3.79672 8.84702 3.78754 9.15274 3.7195 9.44794C3.65147 9.74315 3.52591 10.022 3.35 10.2687C3.02282 10.7274 2.69454 11.2278 2.69485 11.7912L2.69638 14.5562C2.6967 15.1424 3.17264 15.6171 3.75882 15.6158L7.94077 15.607Z"
        fill="url(#paint0_linear_18058_4146)"
      />
      <path
        d="M10.4762 5.44034C10.5118 5.36744 10.4849 5.27942 10.4146 5.23891C10.3443 5.19839 10.2547 5.21921 10.2094 5.28655L9.25612 6.70563C8.65688 7.59765 7.51182 7.94646 6.51584 7.54198C6.40738 7.49793 6.30854 7.5559 6.26789 7.63071C6.22705 7.70586 6.2325 7.82124 6.33011 7.8877C7.21897 8.49287 7.5562 9.64189 7.13537 10.6314L6.41433 12.3269C6.38253 12.4017 6.41418 12.4884 6.48672 12.5251C6.55925 12.5617 6.6478 12.5359 6.68918 12.4659L7.57342 10.9709C8.14025 10.0125 9.32568 9.61665 10.3548 10.0418C10.5725 10.1317 10.7386 9.83025 10.5403 9.69641C9.61608 9.07279 9.29255 7.86356 9.7819 6.86174L10.4762 5.44034Z"
        fill="white"
        stroke="white"
        strokeWidth="0.308644"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_18058_4146"
        x1="15.5298"
        y1="4.91915"
        x2="1.27242"
        y2="13.1507"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#49EE9C" />
        <stop offset="1" stopColor="#078842" />
      </linearGradient>
      <clipPath id="clip0_18058_4146">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.550049)"
        />
      </clipPath>
    </defs>
  </svg>
);
