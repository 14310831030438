export const CurvedSeparator = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="762"
      height="36"
      viewBox="0 0 762 36"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0.724854V36H762V0.724854C762 0.724854 553.095 35 399.198 35C119.5 35 0 0.724854 0 0.724854Z"
        fill="white"
      />
    </svg>
  );
};
